import React from "react"
import {
    Link,
    graphql
} from 'gatsby'
import Img from 'gatsby-image';

import styled from "styled-components"

import GetStarted from "../components/sections/getstarted"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import {
    Container
} from "../components/global"
import Footer from "../components/sections/footer"

import { useFirebase } from "gatsby-plugin-firebase"

const BlogPage = (props) => {
    useFirebase(firebase => { firebase.analytics().logEvent("page_view_blog") }, [])
    const postList = props.data.allMarkdownRemark;
    return (
        <Layout>
    <SEO title="apocha" />
    <Navigation />
    <BlogContainer>
      <h3>apocha Blog</h3>
      <PostsGrid>
      {postList.edges.map(({ node }, i) => (
        <PostLink to={node.fields.slug} className="link" >
          <PostItem>
            <p>{node.frontmatter.date}</p>
            <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid}></Img>
              <PostTitle>{node.frontmatter.title}</PostTitle>
            <PostText>{node.excerpt}</PostText>
          </PostItem>
        </PostLink>
      ))}
      </PostsGrid>
    </BlogContainer>
    <GetStarted />
    <Footer />
  </Layout>
    )
}

export default BlogPage

export const listQuery = graphql `
  query ListQuery {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(blog)/"  }},
      sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields{
            slug
          }
          excerpt(pruneLength: 256)
          frontmatter {
            date(formatString: "Do MMMM YYYY", locale: "de")
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
const BlogContainer = styled(Container)
`
  display: flex;
  flex-direction: column;
  padding: 80px 18px 40px;
`
const PostsGrid = styled.div `
  max-width: 1164px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 32px;
  }
`

const PostItem = styled.div `
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  box-shadow: 0 10px 20px 0 rgba(57,58,61,.15);
  border-top: 6px solid #3276ecff; 
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

const PostTitle = styled.h4 `
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const PostText = styled.p `
  
`

const PostLink = styled(Link)
`
  color: ${props => props.theme.color.primary};
  text-decoration: none;
`
